import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import NavLink from "../components/nav-link"
import NewsletterCard from "../components/newsletter-card"

const Newsletters = ({ pageContext, location }) => {
  const { group, index, first, last /*pageCount*/ } = pageContext
  const previousUrl = index - 1 === 1 ? "/" : (index - 1).toString()
  const nextUrl = (index + 1).toString()
  const basePath = "newsletters"
  return (
    <Layout location={location}>
      <SEO title="Newsletters"></SEO>
      {/* Case Studies */}
      <section className="newsletter__list">
        <div className="container">
          <div className="row row--center">
            <div className="col col__6 col__l10 col__m11">
              <h1 className="title title--center">Newsletters</h1>
              {group.map(({ node }) => (
                <NewsletterCard newsletter={node}></NewsletterCard>
              ))}
            </div>
          </div>
          <div className="row">
            <ul className="pagination">
              <li className="pagination__item current">
                <NavLink
                  basePath={basePath}
                  test={first}
                  url={previousUrl}
                  text="< Go to Previous Page"
                />
              </li>
              <li className="pagination__item">
                <NavLink
                  basePath={basePath}
                  test={last}
                  url={nextUrl}
                  text="Go to Next Page >"
                />
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Newsletters
